/** @format */

import React, { useState } from 'react';
import {
	MDBContainer,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarToggler,
	MDBIcon,
	MDBNavbarNav,
	MDBNavbarItem,
	MDBNavbarLink,
	MDBBtn,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBCollapse,
} from 'mdb-react-ui-kit';

export default function App() {
	const [openBasic, setOpenBasic] = useState(false);
	const [openNavRight, setOpenNavRight] = useState(false);
	return (
		<header>
			<MDBNavbar expand='lg' light bgColor='white'>
				<MDBContainer fluid>
					<MDBNavbarBrand href='#'>
						<img src='/hextGen_logo.png' height='60' alt='' loading='lazy' />
					</MDBNavbarBrand>
					<MDBNavbarToggler
						type='button'
						data-target='#navbarRightAlignExample'
						aria-controls='navbarRightAlignExample'
						aria-expanded='false'
						aria-label='Toggle navigation'
						onClick={() => setOpenNavRight(!openNavRight)}>
						<MDBIcon icon='bars' fas />
					</MDBNavbarToggler>

					<MDBCollapse navbar open={openNavRight}>
						<MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
							<MDBNavbarItem>
								<MDBNavbarLink
									active
									className='fw-bold'
									aria-current='page'
									href='#aboutUs'>
									ABOUT
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink
									active
									className='fw-bold'
									aria-current='page'
									href='#ourServices'>
									OUR SERVICES
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink
									active
									className='fw-bold'
									aria-current='page'
									href='#products'>
									PRODUCTS
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink
									active
									className='fw-bold'
									aria-current='page'
									href='#contactUs'>
									CONTACT US
								</MDBNavbarLink>
							</MDBNavbarItem>
						</MDBNavbarNav>
					</MDBCollapse>
				</MDBContainer>
			</MDBNavbar>
			<div className='bg-color' id='aboutUs'>
				<MDBContainer>
					<div className='row'>
						<img
							alt='image'
							className='bg-image col-5 mt-5 mb-5'
							src='/main.jpg'
						/>
						<div className='col-md-7 mt-5'>
							<h1 className='mb-3'>Revolutionize Your Hospital with HextGen</h1>
							<p className='mb-3'>
								HextGen is a leading technology company that provides
								state-of-the-art hospital management. Our innovative solutions
								streamline the management of appointments, prescriptions,
								revenue and more.
							</p>
							<MDBBtn tag='a' outline size='lg' href='#contactUs'>
								Register
							</MDBBtn>
						</div>
					</div>
				</MDBContainer>
			</div>
		</header>
	);
}
