/** @format */

import React, { useEffect, useState } from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import ContactUs from "./Components/ContactUs";
import Features from "./Components/Feature";
import Benefits from "./Components/Benefits";
import ClientTestimonials from "./Components/ClientTestimonials";
import Pricing from "./Components/pricing";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function MainComponent() {
	const [timer, setTimer] = useState(false);
	useEffect(() => {
		if (!timer) {
			setTimer(true);

			const timeRunning = setTimeout(() => {
				setTimer(false);
			}, 1500);
		}
	}, []);
	return (
		<>
			{timer ? (
				<div>
					<div className='welcome-msg logo-color'>Welcome to</div>
					<img
						src='/home1.png'
						className='center'
						height={200}
						width={500}
						alt='symbol'
					/>{" "}
				</div>
			) : (
				<>
					<Header />
					<Features />
					{/*<Benefits />
					<Pricing />
					<ContactUs />
					<Footer /> */}
					<AnimationOnScroll
						animateIn='animate__fadeInLeftBig'
						animateOnce={true}>
						<Benefits />
					</AnimationOnScroll>
					<AnimationOnScroll
						animateIn='animate__fadeInRightBig'
						animateOnce={true}>
						<Pricing />
					</AnimationOnScroll>
					{/* <AnimationOnScroll
						animateIn='animate__fadeInLeftBig'
						animateOnce={true}>
						<ClientTestimonials />
					</AnimationOnScroll> */}
					<AnimationOnScroll
						animateIn='animate__fadeInLeftBig'
						animateOnce={true}>
						<ContactUs />
					</AnimationOnScroll>
					{/* <AnimationOnScroll
						animateIn='animate__fadeInRightBig'
						animateOnce={true}> */}
					<Footer />
					{/* </AnimationOnScroll> */}
				</>
			)}
		</>
	);
}
