/** @format */

import React from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";

const HospitalOperations = () => {
	return (
		<MDBContainer className='content-container'>
			<h2 className='section-title'>
				Transform Your Hospital Operations with Our All-in-One Solution
			</h2>
			<div className='features-row'>
				<div className='features-column'>
					<ul className='features-list'>
						<li>
							<span className='feature-heading'>Centralized Management:</span>
							<span className='feature-description'>
								Simplify all hospital operations with a single platform.
							</span>
						</li>
						<li>
							<span className='feature-heading'>Boost Patient Retention:</span>
							<span className='feature-description'>
								Deliver superior care and streamline processes to keep patients
								coming back.
							</span>
						</li>
						<li>
							<span className='feature-heading'>
								Smart Appointment Scheduling:
							</span>
							<span className='feature-description'>
								Ensure effortless bookings and reduced wait times.
							</span>
						</li>
						<li>
							<span className='feature-heading'>
								Digitized Patient Records:
							</span>
							<span className='feature-description'>
								Securely store and manage all cases with customizable workflows.
							</span>
						</li>
						<li>
							<span className='feature-heading'>
								Pharmacy Management Made Easy:
							</span>
							<span className='feature-description'>
								Integrated system with real-time stock control and alerts.
							</span>
						</li>
						<li>
							<span className='feature-heading'>
								Advanced Supplier Analytics:
							</span>
							<span className='feature-description'>
								Optimize procurement with detailed insights and reports.
							</span>
						</li>
						<li>
							<span className='feature-heading'>Lab Integration:</span>
							<span className='feature-description'>
								Access 400+ accurate, insightful diagnostic reports instantly.
							</span>
						</li>
					</ul>
				</div>
				<div className='features-column'>
					<ul className='features-list'>
						<li>
							<span className='feature-heading'>
								Effortless Revenue Tracking:
							</span>
							<span className='feature-description'>
								Monitor all patient services and billing at a glance.
							</span>
						</li>
						<li>
							<span className='feature-heading'>Elevate Online Presence:</span>
							<span className='feature-description'>
								Professional website with built-in SEO and Google Business
								integration.
							</span>
						</li>
						<li>
							<span className='feature-heading'>Enhanced Patient Care:</span>
							<span className='feature-description'>
								Minimize errors and improve outcomes with cutting-edge tech.
							</span>
						</li>
						<li>
							<span className='feature-heading'>Cost-Effective Solution:</span>
							<span className='feature-description'>
								Maximize efficiency while saving costs.
							</span>
						</li>
						<li>
							<span className='feature-heading'>
								World-Class Infrastructure:
							</span>
							<span className='feature-description'>
								Powered by AWS, MongoDB, and Microsoft Azure for reliability.
							</span>
						</li>
						<li>
							<span className='feature-heading'>24/7 Support:</span>
							<span className='feature-description'>
								Expert assistance anytime, ensuring uninterrupted service.
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div className='closing-text'>
				Let us help you revolutionize healthcare delivery—
				<span className='highlight'>
					seamless, efficient, and patient-focused!
				</span>
			</div>
		</MDBContainer>
	);
};

export default HospitalOperations;
