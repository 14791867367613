/** @format */

import logo from "./logo.svg";
import "./App.css";
import MainComponent from "./MainComponent";

function App() {
	return <MainComponent />;
}

export default App;
