/** @format */

import React, { useRef, useEffect, useState } from "react";
import { MDBInput, MDBCheckbox, MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		mobile: "",
		message: "",
	});
	const [errors, setErrors] = useState({});

	const validate = () => {
		const newErrors = {};

		// Name validation
		if (!formData.name.trim()) {
			newErrors.name = "Name is required.";
		}

		// Email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!formData.email.trim()) {
			newErrors.email = "Email is required.";
		} else if (!emailRegex.test(formData.email)) {
			newErrors.email = "Invalid email address.";
		}

		// Mobile number validation
		const mobileRegex = /^[0-9]{10}$/;
		if (!formData.mobile.trim()) {
			newErrors.mobile = "Mobile number is required.";
		} else if (!mobileRegex.test(formData.mobile)) {
			newErrors.mobile = "Invalid mobile number. Must be 10 digits.";
		}

		// Message validation
		if (!formData.message.trim()) {
			newErrors.message = "Message is required.";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0; // No errors means valid
	};

	// Handle input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validate()) {
			return; // Stop submission if validation fails
		}

		try {
			const response = await fetch("https://formspree.io/f/xvgowyrr", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				toast.success("Message sent successfully!", { autoClose: 3000 });
				setFormData({ name: "", email: "", mobile: "", message: "" }); // Reset form
				setErrors({});
			} else {
				toast.error("Failed to send the message. Please try again.", {
					autoClose: 3000,
				});
			}
		} catch (error) {
			console.error("Error submitting the form:", error);
			toast.error("Failed to send the message. Please try again.", {
				autoClose: 3000,
			});
		}
	};
	return (
		<div className='bg-color'>
			<MDBContainer className='mt-5' id='contactUs'>
				<h2 className='header-center pt-5'>CONTACT US</h2>
				<form className='col-md-10 offset-md-1 mt-5' onSubmit={handleSubmit}>
					<MDBInput
						id='form4Example1'
						wrapperClass='mb-4'
						label='Name'
						name='name'
						value={formData.name}
						onChange={handleChange}
						required
					/>
					{errors.name && <p className='text-danger'>{errors.name}</p>}

					<MDBInput
						type='email'
						id='form4Example2'
						wrapperClass='mb-4'
						label='Email address'
						name='email'
						value={formData.email}
						onChange={handleChange}
						required
					/>
					{errors.email && <p className='text-danger'>{errors.email}</p>}

					<MDBInput
						type='tel'
						id='form4Example3'
						wrapperClass='mb-4'
						label='Mobile Number'
						name='mobile'
						value={formData.mobile}
						onChange={handleChange}
						required
					/>
					{errors.mobile && <p className='text-danger'>{errors.mobile}</p>}

					<MDBInput
						wrapperClass='mb-4'
						textarea
						id='form4Example4'
						rows={4}
						label='Message'
						name='message'
						value={formData.message}
						onChange={handleChange}
						required
					/>
					{errors.message && <p className='text-danger'>{errors.message}</p>}

					<MDBBtn type='submit' className='mb-4' block>
						Send
					</MDBBtn>
				</form>

				{/* Toast Container */}
				<ToastContainer position='top-right' hideProgressBar />
			</MDBContainer>
		</div>
	);
}
