/** @format */

import React from "react";
import { MDBInput, MDBCheckbox, MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import {
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBCardImage,
	MDBRipple,
} from "mdb-react-ui-kit";

export default function App() {
	return (
		<>
			<MDBContainer className='mt-5' id='ourServices'>
				<h2 className=' mb-3 pt-5'>OUR SERVICES</h2>
				<h4 className='header-center'>Clinic Management Software</h4>
				<div className='row mt-3'>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/appointment.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Appointments
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>Set up timings without any hurdle</li>
										<li>Manage patient appointments with IVR, app or online</li>
										<li>Handle the queue in a waiting room instantly</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/bil.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Billing
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Systematize the recording of payments & creation of bills
										</li>
										<li>Printed receipts signifying the brand name</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/records.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Patients Records
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Keep a track of your patient visits & history; easily and
											digitally!
										</li>
										<li>Printed prescriptions for easy understanding</li>
										<li>Improved care</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/revenue.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Revenues
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Revenue management helps track the income generated.
										</li>
										<li>
											It also provides insights into the number of visited
											patients.
										</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/safe.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Safe and Secure
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Confidentiality is priority! With the practice management
											software, data is safe and secure
										</li>
										<li>We comply with the international data norms</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/inventory.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Inventory
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>Know all the information about your inventory</li>
										<li>Get reminders about stock levels and refill</li>
										<li>Know your revenues on the inventory sales</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
				</div>
			</MDBContainer>

			<MDBContainer className='mt-5' id='ourProducts'>
				<h4 className='header-center'>Multi-Speciality Management Software</h4>
				<div className='row mt-3'>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/ip.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									In Patient Management
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									Provide a connected experience with integrated billing, sample
									collection, automated lab and electronic test reports.
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/24hrs.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Manage OPD
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									Organize your OPD into a paperless experience - from booking
									appointments, making payments, recording clinical data,
									getting prescription and lab reports.
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/lab.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Lab Management
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									Provide a connected experience with integrated billing, sample
									collection, automated lab and electronic test reports.
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/inventory.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Store And Inventory
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<li>Know all the information about your inventory </li>
									<li>Get reminders about stock levels and refill</li>
									<li> Know your revenues on the inventory sales</li>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>

					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/erec.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Medical/Health Records
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>Set up timings without any hurdle.</li>
										<li>Manage patient appointments online from anywhere.</li>
										<li>
											Handle appointments for patients who are not in attendance
											by rescheduling them.
										</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/appointment.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Appointments
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>Set up timings without any hurdle</li>
										<li>Manage patient appointments with IVR, app or online</li>
										<li>Handle the queue in a waiting room instantly</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/bil.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Billing
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Systematize the recording of payments & creation of bills
										</li>
										<li>Printed receipts signifying the brand name</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
					<div className='col-md-3 col-12 flip-card mt-4'>
						<MDBCard
							style={{
								backgroundColor: "#3d84a8",
							}}
							className='flip-card-inner'>
							<MDBRipple
								rippleColor='light'
								rippleTag='div'
								className='bg-image hover-overlay text-center flip-card-front'>
								<img
									src='/safe.png'
									fluid
									alt='...'
									height='200'
									width={200}
									// className='rounded ml-3'
								/>

								<MDBCardTitle className='text-center text-light fw-bold mb-3'>
									Safe and Secure
								</MDBCardTitle>
							</MDBRipple>
							<MDBCardBody className='flip-card-back text-light'>
								<MDBCardText>
									<ul>
										<li>
											Confidentiality is priority! With the practice management
											software, data is safe and secure
										</li>
										<li>We comply with the international data norms</li>
									</ul>
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</div>
				</div>
			</MDBContainer>
		</>
	);
}
